<template>
  <navbar type="primary" position="fixed" menu-classes="ml-auto">
    <!-- <template slot-scope="{ toggle, isToggled }"> -->
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img v-lazy="logomenu" />
      </router-link>
      <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">Su Patrimonio en Manos Expertas</div>
      </el-popover>

      <div class="centrar" v-if="representacion.representacionNombre != null">
        <h5 class="Titulo">
          Oficina: {{ representacion.representacionNombre }}
        </h5>
      </div>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item active">
        <a class="nav-link" href="#inicio">
          <p>{{ Inicio }}</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#quienessomos">
          <p>{{ quienessomos }}</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#pricing">
          <p>{{ pricing }}</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#requisitos">
          <p>{{ requisitos }}</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#solicitudes">
          <p>{{ solicitudes }}</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#findus">
          <p>{{ encuentranos }}</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#eventos">
          <p>{{ eventos }}</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://www.facebook.com/PolizaJuridicaMx/"
          ><i class="fab fa-facebook-square" aria-hidden="true"></i
        ></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://old.polizajuridica.com.mx"
          ><i class="now-ui-icons objects_globe" aria-hidden="true"></i
        ></a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="#" @click="CambiamosIdioma(1)">
          <p>English</p>
        </a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" href="#" @click="CambiamosIdioma(2)">
          <p>Español</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, NavbarToggleButton, Navbar, NavLink } from "@/components";
import { Popover } from "element-ui";
import store from "../store";
import { createTranslator } from "vue-translator";
import traduccion from "../Translate/traduccion";
import logomenu from "../../public/imagenes/Logo_menu.webp";

export default {
  computed: {
    representacion() {
      return store.state.representacion;
    },
    Language() {
      return store.state.Language;
    },
  },
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    DropDown,
    Navbar,
    NavbarToggleButton,
    NavLink,
    [Popover.name]: Popover,
    createTranslator,
  },
  data() {
    return {
      Inicio: "Inicio",
      quienessomos: "Quienes somos",
      pricing: "Precio",
      requisitos: "Requisitos",
      solicitudes: "Solicitudes",
      encuentranos: "Encuentranos",
      eventos: "Eventos",
      types: ["white"],
      logomenu: logomenu,
    };
  },
  methods: {
    maketraduccion() {
      //console.log(this.Language);
      traduccion.lenguaje.forEach((t) => {
        if (t.idioma == this.Language) {
          t.texto.forEach((k) => {
            //console.log(Object.keys(k));
            if (Object.keys(k.inicio) == "inicio") {
              this.Inicio = k.inicio;
              console.log("entro");
            }
          });
        }
      });
    },
    CambiamosIdioma(tipo) {
      switch (tipo) {
        case 1:
          store.state.Language = "en-US";
          break;
        case 2:
          store.state.Language = "es-MX";
          break;
      }
      this.maketraduccion();
    },
  },
  created() {
    this.maketraduccion();
  },
};
</script>

<style scoped>
.section-space-oficina {
  height: 50px;
  display: block;
  width: 100%;
}
.Titulo {
  font-size: 20px;
  color: white;
  font-weight: bold;
}
.centrar {
  justify-content: center;
}
</style>
