export default{
    "lenguaje": [
      {
        "idioma": "es-MX",
        "texto": [{
          "inicio": "Inicio",
          "nuestrosservicios": "Nuestos Servicios",
          "precios": "Precios",
          "requisitos": "Requirementos",
          "formatos": "Formatos",
          "encuentranos": "Encuéntranos",
          "eventos": "Eventos",
          "slogan": "Su Patrimonio en Manos Expertas",
          "quienessomos": "¿Quiénes somos?",
          "quienessomos1": "Somos una empresa con presencia a nivel nacional preocupada por el bienestar de sus clientes, por lo que buscamos mediante una investigación segura, así como la elaboración de un contrato de Arrendamiento adecuado, disminuir el riesgo de un mal Arrendamiento.",
          "quienessomos2": "Además, en caso de existir una controversia en tu arrendamiento, contamos con dos áreas especializadas, extrajudicial (mediación-negociación) y judicial (litigiosa-demanda), quienes responderán a los problemas de una manera ágil e inmediata.",
          "porquenosotros": "¿Por qué nosotros?",
          "1": "Somos pioneros en materia de Póliza Jurídica cuya marca esta registrada.",
          "2": "Costos Bajos (SÍN DEDUCIBLES).",
          "3": "Porque el Arrendatario también nos importa, buscamos una mediación sana y conveniente.",
          "4": "Aceptamos fiadores de las ciudades en donde tenemos presencia",
          "5": "Fortalecemos la capacitación continua y gratuita para el sector inmobiliario.",
          "6": "Contamos con más de 20 representaciones a nivel nacional.",
          "7": "Resolución de trámite en 2 días hábiles.",
          "8": "Atención personalizada.",
          "9": "Servicio en horas y días no hábiles (previa cita).",
          "10": "Contamos con servicios de administración.",
          "11": "Asesoría Legal sin costo.",
          "12": "Atención rápida y eficiente ante el incumplimiento al contrato de arrendamiento.",
          "13": "Reporte del status de la solución a la controversia y del juicio correspondiente.",
          "arrendamientoseguro": "Arrendamiento Seguro",
          "capacitacion": "Capacitación",
          "asesoriajuridica": "Asesoría Jurídica",
          "investigacionvalidacion": "Investigacón y Validación",
          "arrendamientoseguro1": "Gracias a procesos de calidad eficientes, validando la información proporcionada e investigando antecedentes legales así como la elaboración de un contrato de arrendamiento con clausulado actualizado y considerando particularidades en el mismo, garantizamos que el arrendamiento se hará de una manera segura; en caso de cualquier controversia nosotros nos comprometemos a solucionarla de manera ágil e inmediata. Te protegemos ante la Ley de Extinción de dominio sin costo adicional, en todas nuestras polizas.",
          "capacitacion1": "Desde hace 9 años impartimos cursos calendarizados al gremio inmobiliario con la finalidad de compartir experiencias y prácticas adecuadas, así mismo adecuamos planes de capacitación integral a tu inmobiliaria o asociación con temas diversos.",
          "asesoriajuridica1": "Para el equipo de Póliza Jurídica la sinergia es muy importante de tal forma que hacemos equipo y al momento de contratarnos, nos convertimos en tu Bufete Jurídico, por lo que nuestro grupo de expertos estará disponible para ti sin ningún costo.",
          "investigacionvalidacion1": "La prevención es una práctica cada vez más necesaria, el arrendamiento es un riesgo y para minimizar este riesgo es imprescindible realizar una adecuada validación y en su caso investigación de los prospectos (arrendatarios y fiadores) a arrendar un inmueble, así mismo los fiadores comprados son cada vez más frecuentes con las consecuencias desagradables que ello conlleva",
          "investigacionvalidacion2": "Verificamos en diversas bases de datos, antecedentes legales así como en el buro de crédito su comportamiento crediticio, entre otros mecanismos para verificar la solvencia económica y moral de los firmantes.",
          "cerrar": "cerrar",
          "tituloprecio": "Favor de Ingresar el Importe de la Renta",
          "importerenta": "Importe de la Renta",
          "consultar": "Consultar",
          "polizatradicional1": "Póliza Tradicional",
          "polizatradicional2": "Investigación y Validación de la solvencia del Arrendatario (Prospecto).",
          "polizatradicional3": "Elaboración de Contrato de Arrendamiento Actualizado.",
          "polizatradicional4": "Desalojo de Arrendatario por incumplimiento.",
          "polizatradicional5": "Sín pagos extras ni deducibles.",
          "polizatradicional6": "Con ó sin Fiador.",
          "polizatradicional7": "Prevención ante Ley de Extinción de Dominio.",
          "polizatradicional8": "Escalable a Plus (Aplica restricciones).",
          "polizatradicional9": "Todos nuestros precios son con I.V.A. incluido.",
          "polizaplus": "Póliza Plus",
          "polizaplus1": "Investigación y Validación de la solvencia del Arrendatario (Prospecto).",
          "polizaplus2": "Elaboración de Contrato de Arrendamiento Actualizado.",
          "polizaplus3": "Desalojo de Arrendatario por incumplimiento.",
          "polizaplus4": "Recuperación de Rentas y Servicios no pagados.",
          "polizaplus5": "Sín pagos extras ni deducibles.",
          "polizaplus6": "Con Fiador solvente.",
          "polizaplus7": "Prevención ante Ley de Extinción de Dominio.",
          "polizaplus8": "Todos nuestros precios son con I.V.A. incluido.",
          "titulorequisitos": "Los requisitos para tramitar una Póliza Jurídica son los siguientes:",
          "arrendadorfisica": "Arrendatario (Persona Física)",
          "arrendadormoral": "Arrendatario (Persona Moral)",
          "fiadorF": "Fiador (Persona Física)",
          "fiadorM": "Fiador (Persona Moral)",
          "arrendadorfisica1": "Llenar formato correspondiente (SOLICITUD DE PERSONA FÍSICA).",
          "arrendadorfisica2": "Identificación oficial, INE o Pasaporte (en caso de ser extranjero documento migratorio vigente).",
          "arrendadorfisica3": "Ùltimo comprobante de domicilio.",
          "arrendadorfisica4": "Comprobante de ingresos (nómina) ó estados de cuenta bancarios (últimos 3 meses no aplica tarjeta de crédito ni cuenta de inversión).",
          "arrendadormoral1": "Llenar formato correspondiente (SOLICITUD DE PERSONA MORAL).",
          "arrendadormoral2": "Acta constitutiva legal de la empresa.",
          "arrendadormoral3": "RFC (Hoja de inscripción).",
          "arrendadormoral4": "Poder notarial del representante legal de la empresa",
          "arrendadormoral5": "Identificación oficial del representante legal, INE o Pasaporte",
          "arrendadormoral6": "Ùltimo comprobante de domicilio.",
          "arrendadormoral7": "Comprobante de ingresos (los últimos 3 estados bancarios de la empresa o 3 declaraciones de impuestos)",
          "fiadorF1": "Identificación oficial. INE o Pasaporte",
          "fiadorF2": "Ùltimo comprobante de domicilio.",
          "fiadorF3": "Escritura del inmueble en garantía (libre de gravamen) con número de folio visible del registro público de la propiedad",
          "fiadorF4": "Acta de matrimonio (identificación del cónyuge en caso de estar casado por sociedad conyugal)",
          "fiadorF5": "Copia del predial",
          "fiadorM1": "Acta Constitutiva de la Empresa.",
          "fiadorM2": "RFC de la Empresa",
          "fiadorM3": "Ùltimo comprobante de domicilio.",
          "fiadorM4": "Poder del Representante Legal",
          "fiadorM5": "Identificación del Representante Legal",
          "fiadorM6": "Copia de escritura del inmueble en garantía",
          "fiadorM7": "Pago del último predial",
          "solicitudotorgamiento": "Solicitud de otorgamiento de Póliza",
          "solicitudarrepf": "Solicitud de arrendamiento Persona Física",
          "solicitudarrepm": "Solicitud de arrendamiento Persona Moral",
          "solicitudsoluciones": "Formato de soluciones",
          "solicitudrenovaciones": "Formato de renovación",
          "listaprecios": "Lista de precios",
          "descargar": "Descargar",
          "vermapa": "Ver Mapa",
          "titulo": "Título",
          "descripcion": "Descripción",
          "lugar": "Lugar",
          "fechahorai": "Fecha / Hora Inicio",
          "fechahoraf": "Fecha / Hora Fin",
          "aviso": "Todos los derechos reservados Aviso de privacidad"
      }
        ]
      },
      {
        "idioma": "en-US",
        "texto": [{
          "inicio": "Home",
          "nuestrosservicios": "Our Service",
          "precios": "Prices",
          "requisitos": "Requirements",
          "formatos": "Forms",
          "encuentranos": "Find Us",
          "eventos": "Events",
          "slogan": "You legacy in our expert hands",
          "quienessomos": "About us",
          "quienessomos1": "We are a national coverage company dedicated to the wellbeing of its clients. Therefore, we look for diminishing the risk of a bad leasing deal through a safe, efficient and quick research.",
          "quienessomos2": "Moreover, in case of a leasing controversy, we have two specialized areas: out-of-court conciliation (mediation and negotiation) and court settlement (litigation-lawsuit), who will expeditiously provide immediate solutions to any kind of problem.",
          "porquenosotros": "Why choose us?",
          "1": "We are pioneers in matters of Legal Policy whose trademark is registered.",
          "2": "Low costs.",
          "3": "Because the Tenant also matters to us, we seek a healthy and convenient mediation.",
          "4": "We accept guarantors from the cities where we have a presence",
          "5": "Continuous free training for the real estate sector.",
          "6": "We have more than 20 representations national wide.",
          "7": "Procedure resolution within 2 working days.",
          "8": "Personalized attention",
          "9": "Non-workdays and non-working hours service availability (by prior appointment).",
          "10": "We have management services.",
          "11": "Free legal advice.",
          "12": "Quick and efficient attention in case of a breach of lease contract.",
          "13": "Status report on the dispute settlement and corresponding legal action.",
          "arrendamientoseguro": "Safe Leasehold",
          "capacitacion": "Continuous Training",
          "asesoriajuridica": "Legal Counseling",
          "investigacionvalidacion": "Background Checking and Validation",
          "arrendamientoseguro1": "Due to our efficient quality assurance processes, we guarantee the lease agreement safety. In case of any dispute, we oblige to resolve it immediately and expeditiously.",
          "capacitacion1": "We offer monthly open to the public, free of charge talks to learn more about the issues and current problems in leasing.",
          "asesoriajuridica1": "The moment you hire us, we become your law firm. That is the reason our group of experts will be available for you free of charge.",
          "investigacionvalidacion1": "This is one of the most important parts of our processes. We conduct a thorough background checking of the possible future tenant to ensure the leasing will be risk free.",
          "investigacionvalidacion2": "",
          "cerrar": "close",
          "tituloprecio": "Please type the rent amount",
          "importerenta": "Rent amount",
          "consultar": "Search",
          "polizatradicional1": "Traditional Policy",
          "polizatradicional2": "Lessee (prospect) background checking and validation",
          "polizatradicional3": "Updated lease contract preparation ",
          "polizatradicional4": "Lessee eviction in case of contract breaching",
          "polizatradicional5": "No extra fees or deductibles.",
          "polizatradicional6": "With or without Guarantor",
          "polizatradicional7": "Extinction of Ownership Act prevention",
          "polizatradicional8": "Possibility to upgrade to Plus (restrictions apply)",
          "polizatradicional9": "All our prices include VAT",
          "polizaplus": "Plus Policy",
          "polizaplus1": "Lessee (prospect) background checking and validation",
          "polizaplus2": "Updated lease contract preparation",
          "polizaplus3": "Lessee eviction in case of contract breaching",
          "polizaplus4": "Unpaid rent and services recovery",
          "polizaplus5": "No extra fees or deductibles.",
          "polizaplus6": "With Financially Sound Guarantor",
          "polizaplus7": "Extinction of Ownership Act prevention",
          "polizaplus8": "All our prices include VAT",
          "titulorequisitos": "The requirements to process a Legal Policy are:",
          "arrendadorfisica": "Leaseholder (Natural Person)",
          "arrendadormoral": "Leaseholder (Legal Person)",
          "fiadorF": "Guarantor (Natural Person)",
          "fiadorM": "Guarantor (Legal Person)",
          "arrendadorfisica1": "Fill the agreeing application form (Natural Person Request)",
          "arrendadorfisica2": "Official identification: National Electoral Institute Card, Passport (in case of foreigners, valid migration document)",
          "arrendadorfisica3": "Last proof of residency",
          "arrendadorfisica4": "Proof of income (paycheck) or bank statements (last 3 months, credit card or investment account are not valid)",
          "arrendadormoral1": "Fill the agreeing application form (Legal Person Request)",
          "arrendadormoral2": "Articles of incorporation of the company",
          "arrendadormoral3": "Federal Taxpayer Register (Registration form)",
          "arrendadormoral4": "Power of attorney from the company legal representative ",
          "arrendadormoral5": "Legal representative’s official identification (National Electoral Institute Card, Passport)",
          "arrendadormoral6": "Last proof of residency",
          "arrendadormoral7": "Proof of income (last 3 company’s bank statements or 3 tax returns",
          "fiadorF1": "Official identification: National Electoral Institute Card, Passport (in case of foreigners, valid migration document)",
          "fiadorF2": "Last proof of residency",
          "fiadorF3": "Deed of the property offered as security (unencumbered) with a visible folio number from the Public Property Register.",
          "fiadorF4": "Marriage certificate (spouse’s identification in case of marital regime existent)",
          "fiadorF5": "Copy of the property taxation bill",
          "fiadorM1": "Articles of incorporation of the company",
          "fiadorM2": "Federal Taxpayer Register (Registration form)",
          "fiadorM3": "Last proof of residency",
          "fiadorM4": "Power of attorney from the company legal representative ",
          "fiadorM5": "Legal representative’s official identification",
          "fiadorM6": "Copy of the deed of the property offered as security",
          "fiadorM7": "Property taxation bill",
          "solicitudotorgamiento": "Policy granting request",
          "solicitudarrepf": "Natural person lease request",
          "solicitudarrepm": "Legal person lease request",
          "solicitudsoluciones": "Settlement form",
          "solicitudrenovaciones": "Renewal form",
          "listaprecios": "Prices list",
          "descargar": "Download",
          "vermapa": "See Map",
          "titulo": "Title",
          "descripcion": "Description",
          "lugar": "Place",
          "fechahorai": "Date / Time Begin",
          "fechahoraf": "Date / Time End",
          "aviso": "All rights reserved. Privacy Notice"
      }]
      }
    ]
  };